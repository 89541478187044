import * as React from 'react'
import LayoutWork from '../../components/layoutWork'
import {Container, Row, Col} from "react-bootstrap" 
import head from '../../images/portfolio/eaternet/eaternet-cutlery.png'
import fork from '../../images/portfolio/eaternet/eaternet-fork.png'

const PortfolioEatPage = () => {
  return (
    <LayoutWork pageTitle="Eaternet">
      <Container><p className='info'>Product Design | For | Sale</p></Container><hr/>
      <Container>
        <br/>
        <h1>Eaternet</h1>
        <h2>This project aims to explore the relationship between our consumption habits and industrial design. Specifically, we focus on the role of simple rituals in reconnecting with the food we eat and how industrial design can support or hinder these rituals.</h2>
        <br/>
        <img src={head} className="fullimg" alt="Fork and knife accessory for phone"/>
        <br/>
        <br/>
        <Row className="align-items-center">
          <Col sm={6}>
              <Row>
              <p>It is widely recognized that our brains have a limited capacity for processing information. Like an all-you-can-eat buffet, we must be mindful of the quantity and quality of information we consume to avoid becoming overwhelmed or unhealthy. Similarly, simple rituals can help us reconnect with our food, but there is a delicate balance between establishing a helpful routine and becoming too strict or regimented.</p>
              <br/>
              <p>Industrial design has a crucial role to play in this context. The field emerged alongside the Industrial Revolution and became associated with functionalism during the twentieth century. However, the tension between functionalism and aesthetics has always been present, as seen in the arts and crafts movement's attempts to offset the rise of the "world of machines" in the late nineteenth century.</p>
              </Row>
          </Col>
          <Col sm={6}>
              <Row>
                <img src={fork} alt="Close-up Fork accessory"/>
              </Row>
          </Col>
        </Row>
        <br/>
        <p>Our project seeks to examine how industrial design can be used to support simple rituals that help us reconnect with food. We aim to identify examples of effective design that facilitate these rituals, as well as those that hinder them. By exploring the relationship between design and consumption habits, we hope to contribute to a broader conversation about how to build more mindful and sustainable relationships with food.</p>
        <br/>
        <iframe width="100%" height="550" src="https://www.youtube.com/embed/246uTUaom2U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </Container>
    </LayoutWork>
    
  )
}
export default PortfolioEatPage